export const statusOptions = [
  {
    title: '-',
    background: 'light'
  },
  {
    title: 'in progress',
    background: 'primary'
  },
  {
    title: 'complete',
    background: 'success'
  }
];